import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { useParams } from 'react-router-dom';
import { FieldControl, IconControl, Loading, DialogControl } from 'spark-core-dx/components';
import WizardFooter from './WizardFooter'
import Accordion from './Accordion';
import { ItemServiceFactory } from '../../../../services/ItemServiceFactory';
import { useWizardDomainContext } from '../../WizardDomainProvider';
import { useValidationErrors, useGenericManager } from 'spark-core-dx/hooks'

const LegacySystems = ({ applicationList, setCurrentTab, setChanges }) => {
    const { hasAndShowValidationErrors } = useValidationErrors()
    const { setCurrentStep, currentStep, updateOnboardingProcess, handleProgress, onboardingItem } = useWizardDomainContext();
    const itemService = new ItemServiceFactory()
    const franchiseeLocationMgr = useGenericManager(itemService.ItemServiceEnum.FranchiseeLocation, itemService);
    const onboardingMgr = useGenericManager(itemService.ItemServiceEnum.Onboarding, itemService);
    const franchiseeExternalAppSettingMgr = useGenericManager(itemService.ItemServiceEnum.FranchiseeExternalAppSetting, itemService);

    const { cid } = useParams();

    const [locations, setLocations] = useState([])
    const [companyIds, setCompanyIds] = useState({});
    const [copiedCompanyIds, setCopiedCompanyIds] = useState({})
    const [errorMessages, setErrorMessages] = useState(null);
    const [success, setSuccess] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false)
    const [errorDialogOpen, setErrorDialogOpen] = useState(false)

    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        setIsLoading(false)
    }, [])

    useEffect(() => {
        franchiseeLocationMgr.search({ searchModel: `x => x.ClientId == cid && x.IsActive == true && x.IsDeleted == false`, params: { cid: cid } }).then(r => {
            setLocations(r)
        })
        if (applicationList) {
            if (Object.keys(applicationList)?.includes('ValueBuilder')) {
                if (applicationList['ValueBuilder']?.ValueBuilderConfigs?.length > 0) {
                    const vbconfigs = applicationList['ValueBuilder']?.ValueBuilderConfigs.reduce((acc, config) => {
                        acc[config.LocationId] = config.CompanyIds;
                        return acc;
                    }, {});
                    setCompanyIds(vbconfigs)
                    setCopiedCompanyIds(vbconfigs)
                }
            }
        }
    }, [])

    useEffect(() => {
        if (!isLoading) {
            setChanges(hasChanges())
        }
    }, [companyIds, copiedCompanyIds])

    const hasChanges = () => {
        const vbChanged = JSON.stringify(companyIds) !== JSON.stringify(copiedCompanyIds);

        return vbChanged
    };

    const handleCompanyIds = (location, value) => {
        setCompanyIds(prev => ({
            ...prev,
            [location]: value
        }));
    };

    const renderValueBuilder = () => {
        return (
            <div className='locations-container'>
                {locations?.map(location => {
                    return (
                        <Accordion key={location?.LocationName} title={location?.LocationName} opened={true}>
                            <FieldControl
                                key={'companyIds'}
                                fieldMetadata={{
                                    FieldType: 15,
                                    FieldName: 'CompanyIds',
                                    DisplayName: 'Company IDs',
                                }}
                                value={companyIds[location.UniqueId]}
                                onChange={(value) => handleCompanyIds(location.UniqueId, value)}
                            />
                        </Accordion>
                    )
                })}
            </div>
        )
    }

    const legacySystemsRender = {
        "ValueBuilder": locations.length > 0 ? renderValueBuilder() : <Loading type={'spinner-2'} />,
        "LincLaser": 'tbd'
    }

    const handleSave = async () => {
        if (applicationList['ValueBuilder']) {
            const valueBuilderConfigs = Object.entries(companyIds).map(([locationId, companyIds]) => ({
                LocationId: locationId,
                CompanyIds: companyIds
            }));
            applicationList['ValueBuilder'].ValueBuilderConfigs = valueBuilderConfigs
            await franchiseeExternalAppSettingMgr.saveItem(applicationList['ValueBuilder']).then(r => {
                if (r.Success) {
                    setCopiedCompanyIds(companyIds)
                }
            })
        }
        await handleProgress(1, 1, 'page74', updatedPageValues => {
            updateOnboardingProcess(currentStep, updatedPageValues);
        });
    }

    const renderDialog = () => {
        // Connect, if success.. go to next page. If fail, error dialog.
        if (dialogOpen) {
            return (
                <DialogControl
                    openDialog={dialogOpen}
                    title={!success ? `Creating Connections` : [<IconControl className="success" iconKey={'success'} />, "Success"]}
                    className={'with-icon'}
                    disableCancel={true}
                    disableXButton={true}
                    hideButtonRow={!success}
                    okText={"Continue"}
                    onOk={async () => {
                        await handleProgress(1, 1, 'page74', updatedPageValues => {
                            updateOnboardingProcess(currentStep + 1, updatedPageValues);
                        });
                        setCurrentStep(currentStep + 1)
                    }} // Temporary to push to next step.
                >
                    {!success ? <div className="dialog-text" style={{ textAlign: 'center' }}>
                        <div style={{ marginBottom: '1rem' }}>
                            <p>This may take a few moments.</p>
                        </div>
                        <Loading type={'spinner-2'} />
                    </div> :
                        <div className="dialog-text">
                            <p>
                                All systems and applications have been successfully connected.
                            </p>
                            <p>
                                Please create a ValueBuilder migration ticket to begin migrating data.
                            </p>
                        </div>
                    }
                </DialogControl>
            )
        } else {
            return (
                <DialogControl
                    className={'with-icon'}
                    openDialog={errorDialogOpen}
                    title={[<IconControl className={'error'} iconKey={'error'} />, "Connection Failed"]}
                    disableCancel={true}
                    disableXButton={true}
                    onOk={() => {
                        setErrorDialogOpen(false)
                        setErrorMessages(null)
                    }}
                    okText={'Return'}>
                    {typeof errorMessages === "object" ? _.map(errorMessages, (value, key) => {

                        return <div className="error" key={key}>
                            {key} : {value}
                        </div>
                    }) :
                        <div className="error">
                            {errorMessages}
                        </div>
                    }
                </DialogControl>
            )
        }
    }

    return (
        <>
            {renderDialog()}
            <div className='wizard-content'>
                {
                    applicationList && Object.keys(applicationList)?.length > 0
                        ? (
                            Object.keys(applicationList)?.map((app) => (
                                legacySystemsRender[app] ? (
                                    <div className="application-container" key={app}>
                                        <h3>{app}</h3>
                                        {legacySystemsRender[app]}
                                    </div>
                                ) : (
                                    <div key={app}>
                                        <p>{app} is not yet implemented.</p>
                                    </div>
                                )
                            ))
                        ) : isLoading ?
                            <Loading type={'spinner-2'} />
                            : <p>No applications selected.</p>
                }
            </div>
            <WizardFooter
                disableAll={isLoading}
                showPrevBtn={true}
                prevBtnOnClick={() => setCurrentTab(3)}
                showSecBtn={true}
                primaryBtnOnClick={async () => {
                    if (!hasAndShowValidationErrors()) {
                        setDialogOpen(true)

                        await handleSave();

                        let result = await onboardingMgr.RunOperation({
                            operationName: "ValidateAppSetup",
                            queryParams: {
                                cid: onboardingItem.ClientId,
                            },
                        })
                        if (result.Success) {
                            const appStatuses = result.Items?.first().AppStatuses;

                            _.every(appStatuses, (value, key) => console.log(value, key))
                            if (!_.every(appStatuses, (value, key) => value === "Success")) {
                                setDialogOpen(false)
                                setErrorDialogOpen(true)
                                setErrorMessages(appStatuses)
                            } else {
                                setSuccess(true)
                            }
                        } else {
                            setErrorMessages("Failure")
                        }
                    }
                }}
                secBtnOnClick={async () => {
                    if (!hasAndShowValidationErrors()) {
                        await handleSave();
                    }
                }}
                primaryBtnText={"Connect"}
                secBtnText={"Save"} />
        </>
    )
}

export default LegacySystems